import React from 'react'
import { graphql, Link, navigate } from 'gatsby'

const path = (group) => `/events/groups/${group.slug}`

const GroupLink = ({ group }) => <Link to={path(group)}>{group.id}</Link>

export const activityNavigate = (group) => () => navigate(path(group))

export default GroupLink

export const query = graphql`
  fragment GroupLink on GroupsYaml {
    id
    slug
  }
`
