import React from 'react'
import { graphql, Link, navigate } from 'gatsby'

const path = (venue) => `/events/venues/${venue.slug}`

const VenueLink = ({ venue }) => <Link to={path(venue)}>{venue.id}</Link>

export const activityNavigate = (venue) => () => navigate(path(venue))

export default VenueLink

export const query = graphql`
  fragment VenueLink on VenuesYaml {
    id
    slug
  }
`
