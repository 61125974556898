import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import format from 'date-fns/format'
import Layout from '../components/layout'

import EventTable from '../components/event_table'
import { DLSingleItem } from '../components/definition_list'
import GroupLink from '../components/group_link'
import VenueLink from '../components/venue_link'

export default function Template({
  data: {
    site: {
      siteMetadata: { title: siteTitle },
    },
    event,
  },
}) {
  const { title, group, venue } = event
  return (
    <Layout>
      <Helmet title={`${siteTitle} - ${title}`} />
      <div id="pageheader">
        <h1>Venue</h1>
        <span>{title}</span>
      </div>
      <dl className="dl-horizontal">
        <DLSingleItem name="Title" data={title} />
        <DLSingleItem name="Ensemble" data={<GroupLink group={group} />} />
        <DLSingleItem name="Venue" data={<VenueLink venue={venue} />} />
      </dl>
      <EventTable
        title="Events"
        events={[event]}
        date_format="MMMM dd, yyyy"
        cols={2}
        header={() => (
          <tr>
            <th>Event</th>
            <th>Time</th>
          </tr>
        )}
        activity={(activity, activity_index) => (
          <tr key={activity.event.id + '.' + activity_index}>
            <th scope="row">{activity.name}</th>
            <td className="nowrap">{format(activity.date_time, 'hh:mm a')}</td>
          </tr>
        )}
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query EventById($id: String!) {
    event: eventsYaml(id: { eq: $id }) {
      id
      title
      published
      group {
        ...GroupLink
      }
      venue {
        ...VenueLink
      }
      activities {
        name
        public
        duration
        created_date_time
        date_time
      }
    }
    site: site {
      siteMetadata {
        title
      }
    }
  }
`
